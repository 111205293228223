<template>
  <div class="h-100">
    <div class="overflow-scroll p-1" ref="scrollable">
      <div class="d-flex align-items-center mb-1 flex-wrap flex-xl-nowrap">
        <div class="d-flex mb-1 mb-xl-0 w-100 align-items-center">
          <b-button variant="outline-secondary" class="size-button-center" @click="$emit('slide_window')">
             <span><feather-icon icon="MenuIcon"/></span>
          </b-button>
          
          <b-input-group class="ml-1 w-lg-auto">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon"></feather-icon>
            </b-input-group-prepend>
            <b-form-input :placeholder="$t('ia.community.findPrompt')" class="input-search-history" v-model="query"></b-form-input>
            <b-input-group-append is-text class="cursor-pointer" v-if="query && query.length > 0" @click="query = ''">
              <feather-icon icon="XIcon"></feather-icon>
            </b-input-group-append>
          </b-input-group>
        </div>
        <b-button 
          variant="blue-button" 
          class="blue-button create-your-own-button ml-xl-1 h-100"
          @click="redirectCreate()"
        >
          {{ $t('ia.community.creteOwn') }}
        </b-button>
      </div>

      <div class="border-bottom py-2" v-if="!query">
        <h2>{{ $t('ia.community.popularThemes') }}</h2>
        <div class="grid-covers">
          <div 
            class="cover-term" 
            v-for="(image, index) in popular_themes.slice(0, 2)" 
            :key="index"
            @click="query = image.title"
          >
            <b-img :src="image.static_image" class="image-cover-term"></b-img>
          </div>
        </div>
      </div>

      <div class="py-2 overflow-results" :class="{'border-bottom': images_community.length > 0}">
        <h3>{{ $t('ia.community.iaCommunity') }}</h3>
        <p class="avenir-medium" v-if="query">{{ $t('ia.community.searchKey')}}: {{ query }}</p>

        <vue-masonry-wall 
          :items="images_community" 
          :options="{width: 320, padding: 8}" 
          @append="getCommunityImages"
          ref="grid_item"
          v-if="(images_community.length > 0)"
        >
          <template v-slot:default="{item}">
            <div :class="{'border-light-grid': rendered}" class="container-image-grid-community" @click="clickCardOpenModal(item)">
              <b-img :src="item.images[0] ? item.images[0] : ''" class="img-community" @error="errorLoad(item)" @load="renderedImage"></b-img>
              <div class="hover-container-info">
                <div>
                  <div class="d-flex justify-content-between container-top-hover align-items-center">
                    <div>
                      <b-avatar class="mr-05" :src="getAssetUrl(item.user.avatar)"></b-avatar> <span>{{ item.user.first_name }}</span>
                    </div>
                    <div @click.stop="downloadFile(item.images[0])">                      
                      <feather-icon icon="DownloadIcon" size="20"></feather-icon>
                    </div>
                  </div>
                </div>
                <span class="container-bottom-hover">{{ item.processed_prompt.substring(0, 100) }}
                {{ item.processed_prompt.length > 100  ? '...' : ''}}
                </span>
              </div>
            </div>
          </template>
        </vue-masonry-wall>
        <div v-else class="container-loader-grid-results">
          <b-spinner class="spinner"></b-spinner>
        </div>
      </div>
    </div>

    <b-modal v-model="show_modal" hide-footer hide-header centered id="modal-backdrop-swiper" size="lg">
      <div class="container-modal-extra-info row" v-if="Object.keys(image_selected).length > 0">
        <b-img :src="image_selected.images[0]" class="image-selected col-12 col-lg-6 p-0"></b-img>
        <div class="col-12 col-lg-6 p-2">
          <div class="border-bottom pb-2">
            <div class="mb-2">
              <b-avatar class="avatar" :src="getAssetUrl(image_selected.user.avatar)"></b-avatar> <span>{{ image_selected.user.first_name }}</span>
            </div>
            <h2 class="mb-2">{{ image_selected.original_prompt }}</h2>
            <div>
              <p class="text-muted avenir-medium text-uppercase">PROMPT</p>
              <p>{{ image_selected.processed_prompt }}</p>
            </div>
            <b-button variant="outline-secondary" @click.once="downloadFile(image_selected.images[0])">{{ $t('mediaKits.download')}}</b-button>
          </div>
          <div class="pt-2 row">
            <div class="col-6 mb-1">
              <span class="d-block avenir-medium text-uppercase">{{ $t('ia.community.model')}}</span>
              <span class="d-block">{{ image_selected.data.model ? $t('search.ia_options').find((i) => i.value === image_selected.data.model).label : '--'}}</span>
            </div>


            <div class="row m-0 w-100">
              <div class="col-6">
                <span class="d-block avenir-medium text-uppercase">{{  image_selected.data.model !== 'imagine' ? 'SIZE' : 'ASCPECT RATIO' }}</span>
                <span class="d-block">{{ image_selected.data.model !== 'imagine' ? image_selected.size_images : image_selected.data.aspect_ratio }}</span>
              </div>
              <div class="col-6">
                <span class="d-block avenir-medium text-uppercase">{{ $t('search.negativePromp') }}</span>
                <span class="d-block"> {{ image_selected.data.negative_prompt ? image_selected.data.negative_prompt : '--' }}</span>
              </div>
            </div>
          </div>
        </div>
        <feather-icon
          icon="XIcon"
          size="24"
          class="close-icon-modal"
          @click="show_modal = !show_modal"
        />
      </div>
    </b-modal>
  </div>
</template>
    
<script>

import {
  BSpinner,
  BImg,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BAvatar,
  BModal,
} from 'bootstrap-vue';
import config from '@/services/config';
import { getCommunityImages, getPopularThemes } from '@/services/ia'
import { getAssetUrl } from '@/libs/utils/urls';
import { downloadFile } from '@/libs/utils/others';
import VueMasonryWall from 'vue-masonry-wall';

export default {
  name: 'communityGrid',
  components: {
    BSpinner,
    BImg,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BAvatar,
    BModal,
    VueMasonryWall
  },
  data() {
    return {
      getAssetUrl,
      downloadFile,
      axios: null,
      cancel_search_ws: null,
      query: undefined,
      loaded_service: false,
      images_community: [],
      update_image: false,
      popular_themes: [],
      show_modal: false,
      image_selected: {},
      rendered: false,
    }
  },
  props: {
    category_selected: {
      type: Object,
      default: () => {
        return {}
      },
    }
  },
  async mounted() {
    this.axios = (await import('axios')).default;
    this.$refs.scrollable.addEventListener('scroll', this.checkVisibility);
    this.checkVisibility();
  },
  created() {
    this.getCommunityImages();
    this.getPopularThemes();
  },
  methods: {
    checkVisibility() {
      const scroll_area = this.$refs.scrollable;
      const grid_items = this.$refs.grid_item ? this.$refs.grid_item.$el.children : [];
      let items_grid = [];
      grid_items.forEach(i => {
        items_grid = [...items_grid, ...i.children]
      });
      const scroll_area_rect = scroll_area.getBoundingClientRect();
      
      if (items_grid.length > 0) {
        items_grid.forEach(item => {
          const rect = item.getBoundingClientRect();
          const is_sort_of_visible = (rect.bottom >= scroll_area_rect.top && rect.top <= scroll_area_rect.bottom);
          if (is_sort_of_visible) {
            item.classList.add('get-animation-blur-scale')
          } else {
            item.classList.remove('get-animation-blur-scale')
          }
        });
      }
    },
    renderedImage() {
      this.rendered = true;
      this.checkVisibility();
    },
    clickCardOpenModal(image) {
      this.image_selected = image;
      this.show_modal = true; 
    },
    getPopularThemes() {
      getPopularThemes().then((response) => {
        this.popular_themes = response.results;
        this.popular_themes.map((i) => {
          i['static_image'] = i.images[Math.floor(Math.random() * i.images.length)]
        })
      })
    },
    getCommunityImages() {
      const data = {
        number: 12,
        prev_images: this.images_community.map((i) => i.uuid),
        query: this.query && this.query.length > 0 ? this.query : undefined
      }
      getCommunityImages(data).then((response) => {
        this.setFormatData(response)
      });
    },
    setFormatData(response) {
      this.images_community = this.images_community.concat(response.results);
      setTimeout(() => {
        this.loaded_service = true;
      }, 20)
    },
    redirectCreate() {
      this.$router.push({ name: 'brandme_AI', params: {section:'image', category: 'txt-to-image'}}).catch(() => {})
      this.category_selected.section = 'image';
      this.category_selected.category = 'txt-to-image';
    },
    errorLoad(index) {
      index.images[0] = require("@/assets/images/dashboard/imgDefault.png");
      this.update_image = !this.update_image;
    },
    searchQuery(query) {
      const CancelToken = this.axios.CancelToken;
      if (this.cancel_search_ws) this.cancel_search_ws()
      const self = this;
      const data = {
        query,
        number: 12
      }
      this.axios({
        method: 'post',
        url: `${config.urlCore}/api/v3/ai/community-images/`,
        data,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        cancelToken: new CancelToken(function executor(c) {
          self.cancel_search_ws = c;
        })
      }).then((response) => {
        this.images_community = [];
        this.setFormatData(response.data.response)
      })
    },
  },
  watch: {
    query(val) {
      if (val.length === 0) {
        this.getCommunityImages();
      } else this.searchQuery(val)
    },
  }
}
</script>
<style lang="scss" scoped>
.border-light-grid {
  border: 1px solid #cccccc71;
}
.container-image-grid-community {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 0.7em;

  .hover-container-info {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.644);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1em;
    color: white;
    opacity: 0;
    transition: all 300ms;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .container-top-hover {
      transform: translateY(-50px);
      transition: all 300ms;
    }
    .container-bottom-hover {
      transform: translateY(100px);
      transition: all 300ms;

    }
  }

  &:hover {
    .hover-container-info {
      opacity: 1;
    }
    .container-top-hover {
      transform: translateY(0px);
    }
    .container-bottom-hover {
      transform: translateY(0px);
    }
  }
}
.overflow-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  .mr-05 {
    margin-right: 0.5em;
  }
  .create-your-own-button {
    flex-shrink: 0;
    width: 100%;
    
    @media(min-width: 1200px) {
      width: auto;
    }
  }
  .grid-covers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2em;
    grid-auto-flow: dense;
    .cover-term {
    
      aspect-ratio:  100% / 200;
      border-radius: 1em;
      font-size: 3em;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100px;
      max-height: 200px;
      cursor: pointer;
      overflow: hidden;

      .image-cover-term {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }

      @media(max-width: 430px) {
        width: 100%;
        aspect-ratio: 0 / 0;
      }
    }
    @media(max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .size-button-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 3rem;
  }
  .input-search-history {
    height: 3rem;
  }
  .container-loader-grid-results {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    .spinner {
      height: 6em;
      width: 6em;
      color: #acacac;
    }
  }
}
.container-modal-extra-info {
  background-color: white;
  padding: 1.2em;
  border-radius: 1em;
  .image-selected {
    width: 100%;
    object-fit: contain;
    border-radius: 0.8em;
    background-color: #cccccc71;
  }
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 0.7em;
  }
  .close-icon-modal {
    position: absolute;
    top: 2em;
    right: 2em;
    color: #979797;
    cursor: pointer;

    @media(max-width: 991px) { 
      background-color: white;
      top: 3em;
      right: 2.7em;
      border-radius: 100%;
    }
  }
}
</style>
<style lang="scss">
#modal-backdrop-swiper {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 9;
  .modal-content {
    border-radius: 2em;
    background-color: transparent;
    box-shadow: none;
  }
}
.img-community {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.get-animation-blur-scale {
  transition: all 1s;
  animation: animation-blur 500ms !important;
  @keyframes animation-blur {
    0% {
      transform: scale(1.15);
      filter: blur(20px);
    }
    100%{
      transform: scale(1);
      filter: none;
    }
  }
}

</style>
